import React from "react";
import { InfoSectionTwo } from "../../components";
import {
  CustomBgSec,
  InfoColumn,
  Screenshot20230621151140RIcon,
  TextWrapper,
  TopLine,
} from "./CustomBg.elements";
import { homeObjFour } from "../../pages/HomePage/Data";
import { Divider } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "../../globalStyles";
// import { Heading2 } from "../InfoSection/InfoSection.elements";
import { HeadingMedium } from "../CustomBg/CustomBg.elements";

function CustomBg({ lightTopLine }) {
  return (
    <CustomBgSec>
      {/* Why choose us Section */}
      <Container>
        <InfoColumn>
          <TopLine lightTopLine={lightTopLine}>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} style={{ marginTop: "16px" }}>
                WHY CHOOSE US?
              </Col>
              <Col xs={12} sm={12} md={2} lg={2}>
                <Divider
                  orientation="left"
                  style={{ borderTop: "3px solid red" }}
                />
              </Col>
            </Row>
          </TopLine>
          {/* <Heading2>
            CHOOSE BIOSEC FOR TRUSTED PARTNERSHIP AND PROVEN SOLUTIONS
          </Heading2> */}
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Screenshot20230621151140RIcon
                    alt=""
                    src="/screenshot-20230621-151140removebgpreview-1@2x.png"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  style={{ marginTop: "10px" }}
                >
                  <HeadingMedium style={{ textTransform: "uppercase" }}>
                    High-end Technology
                  </HeadingMedium>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Screenshot20230621151140RIcon
                    alt=""
                    src="/screenshot-20230621-151140removebgpreview-3@2x.png"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  style={{ marginTop: "10px" }}
                >
                  <HeadingMedium style={{ textTransform: "uppercase" }}>
                    Global Perspective
                  </HeadingMedium>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Screenshot20230621151140RIcon
                    alt=""
                    src="/screenshot-20230621-151140removebgpreview-4@2x.png"
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: "10px" }}
                >
                  <HeadingMedium style={{ textTransform: "uppercase" }}>
                    Efficient Deployment
                  </HeadingMedium>
                </Col>
              </Row>
            </Col>
          </Row>
        </InfoColumn>
      </Container>

      <InfoSectionTwo {...homeObjFour} />
    </CustomBgSec>
  );
}

export default CustomBg;
