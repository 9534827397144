import React from 'react';
import { Container } from '../../globalStyles';
import { Col, Row } from 'antd';
import ProjectCard from '../ProjectCard/ProjectCard';
import {
  ProjectsCardsBg,
  ProjectsCardsRow,
} from './OurProjectsSection.elements';

const ProjectCardsSection = (props) => {
  return (
    <>
      <ProjectsCardsBg>
        <Container>
          <Row gutter={20} style={{ paddingTop: '50px' }}>
            {Object.values(props).map((project) => (
              <Col xs={24} sm={24} md={8} lg={8}>
                <ProjectCard
                  key={project.id}
                  title={project.title}
                  description={project.description}
                  img={project.img}
                  hasButton={project.hasButton}
                  buttonLabel={project.buttonLabel}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </ProjectsCardsBg>
    </>
  );
};

export default ProjectCardsSection;
