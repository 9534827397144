import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
// img
import imgDetail from "../../../assets/img/Images-08.jpg";
import imgDetail2 from "../../../assets/img/Images-07.jpg";

const GetStarted2 = () => {
  const CustomGridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 0, // Remove top and bottom paddings
      }}
    >
      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        component="section"
        style={{ backgroundColor: "#0A0C0D", color: "#FFF" }}
      >
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title text={"OIL AND GAS INDUSTRY SUPPORT"} textAlign={"start"} />
          <CustomTypography style={{ color: "#FFF" }}>
            Our technology aids in monitoring and addressing the issue of
            bunkering, providing valuable data to combat oil theft and
            environmental damage.
          </CustomTypography>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail}
          alt=""
          style={{
            width: "100%",
            height: "400px",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default GetStarted2;
