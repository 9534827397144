import React from "react";
import Icon from "@ant-design/icons";
import { Tabs } from "antd";
import Software from "../../components/Software/Software";
import ItemComponent from "../../components/Software/ItemComponent";
import { Col, Row, Divider } from "antd";
import { Container } from "../../globalStyles";

const items = [
  {
    key: "1",
    label: `Software`,
    children: <Software />,
  },
  {
    key: "2",
    label: `Hardware`,
    children: (
      <>
        <Container style={{marginBottom:"50px"}}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6}>
              <ItemComponent
                name="Tactivo™ Mini for Android"
                src="/card1.png"
                srcModal="/card1.png"
                details="Tactivo Mini for Android is a smartcard and fingerprint reader for Android devices. It enables high-security logins to IT systems from mobile devices with smartcards and fingerprints. It offers a host of use cases, such as document access and signing, and location-based time and attendance register for employees whose main workplace is out of a regular office. It can also serve as a database for fingerprint collection using its ability to export fingerprint images. It is supported by our free toolkit, which ensures its easy implementation."
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <ItemComponent
                name="Tactivo™ Mini for iOS"
                src="/card2.png"
                srcModal="/card2.png"
                details="Tactivo Mini for iOS is a smart card and fingerprint reader for iPhone and iPad. It enables a wide range of use cases for organizations using smart cards, such as secure e-mailing, browsing, document signing, etc. It offers a host of other use cases, such as document access and signing, and location-based time and attendance register for employees whose main workplace is out of a regular office. It can also serve as a database for fingerprint collection using its ability to export fingerprint images."
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <ItemComponent
                name="Neurotech Dongle"
                src="/card3.png"
                srcModal="/card3.png"
                details="Dongle is a hardware device from neurotechnology used as a license server for neurotechnology SDKs."
              />
            </Col>
          </Row>
        </Container>
      </>
    ),
  },
];

function ProductsSection() {
  return <Tabs defaultActiveKey="1" items={items} centered="true" />;
}

export default ProductsSection;
