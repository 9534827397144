import styled from "styled-components";

export const HeaderBackground = styled.div`
  background-image: url("/headrImg.png");
  background-repeat: no-repeat;
  background-position: top;
  padding: 50px 50px;

  @media (min-width: 320px) and (max-width: 960px) {
    padding: 10px 10px;
  }
`;

export const SubtitleFull = styled.p`
  max-width: 100%;
  margin-bottom: 35px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;

export const ProjectsCardsBg = styled.div`
  background-image: url("/project-fingerprint-bg.svg");
  background-repeat: no-repeat;
  background-size: 1200px;
  background-position: right;
  margin-bottom: 100px;
`;

export const Img = styled.img`
  padding-right: 0;
  margin-left: 65px;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 450px;

  @media (min-width: 320px) and (max-width: 960px) {
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Img2 = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 450px;

  @media (min-width: 320px) and (max-width: 960px) {
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ImgContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-left: auto;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
  }
`;
