import React from "react";
import Icon from "@ant-design/icons";
import { Tabs } from 'antd';
import LargeScale from "./LargeScale";
import Eye from "./Eye";
import { Container } from "../../globalStyles";
import Voice from "./Voice";
import Finger from "./Finger";

const items = [
    {
      key: '1',
      label: `Eye Iris SDKs`,
      children: <Eye/>,
    },
    {
      key: '2',
      label: `Face SDKs`,
      children: <Container>
        <p>No Products Available</p>
      </Container>,
    },
      {
        key: '3',
        label: `Large Scale System`,
        children: <LargeScale />,
      },
      {
        key: '4',
        label: `Voice SDKs`,
        children: <Voice/>,
      },
      {
        key: '5',
        label: `Finger Print SDKs`,
        children: <Finger/>,
      },
  ];

  function Software() {
    
    return (
     <Tabs defaultActiveKey="1" items={items} centered="true" />  
    );
  }

  export default Software;