import React from "react";
import { TopLine, Heading, InfoSec } from "../InfoSection/InfoSection.elements";
import { Col, Divider, Row } from "antd";
import {
  Container,
  LightButtonOutline,
  LittleMarg,
  SectionSpacer,
} from "../../globalStyles";
import { HeadingMedium } from "../CustomBg/CustomBg.elements";
import CustomBullet from "../Bullet/bullet";

const CoreStrengthSection = ({ topLine, lightTopLine }) => {
  return (
    <>
      <Container>
        <SectionSpacer>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <TopLine>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    style={{ marginTop: "16px" }}
                  >
                    {topLine}
                  </Col>
                  <Col xs={4} sm={4} md={2} lg={2}>
                    <Divider
                      orientation="left"
                      style={{ borderTop: "3px solid red" }}
                    />
                  </Col>
                </Row>
              </TopLine>
              <HeadingMedium>
                Join us and bring your unique strengths. Opportunities for
                growth and integrity in a culture of innovation, teamwork, and
                transparency.
              </HeadingMedium>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <LittleMarg>
                <TopLine>
                  <CustomBullet /> ARE YOU CREATIVE & INNOVATIVE?
                </TopLine>
                <TopLine>
                  <CustomBullet /> ARE YOU A COLLABORATIVE THINKER?
                </TopLine>
                <TopLine>
                  <CustomBullet /> ARE YOU PROACTIVE IN SEEKING NEW IDEAS ?
                </TopLine>
                <TopLine>
                  <CustomBullet /> DO YOU POSSESS STRONG PROBLEM-SOLVING
                  ABILITIES?
                </TopLine>
              </LittleMarg>
            </Col>
          </Row>
        </SectionSpacer>
      </Container>
    </>
  );
};
export default CoreStrengthSection;
