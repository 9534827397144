import React from "react";
import {
  InfoSection,
  HeroSection,
  InfoSectionThree,
  Footer,
} from "../../components";
import { homeObjOne, homeObjTwo, careerTwo, managementTeam } from "./Data";
import NewsletterSection from "../../components/Newsletter/NewsletterSection";
import CustomBg from "../../components/CustomBg/CustomBg";
import TimelinesSection from "../../components/Timelines/TimelinesSection";
import StorySection from "../../components/Story/StorySection";
import TeamSection from "../../components/Team/TeamSection";

const About = () => {
  return (
    <>
      <StorySection {...homeObjTwo} />
      <TimelinesSection {...homeObjOne} />
      <TeamSection {...managementTeam} />
      <Footer />
    </>
  );
};

export default About;
