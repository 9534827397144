import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from React Router
import backgroundImg from "../../assets/img/remote.mp4.mp4";
import backgroundImg2 from "../../assets/img/identity.mp4.mp4";
import "../../index.css";
import {
  Heading,
  Subtitle,
} from "../../components/HeroSection/HeroSection.elements";
import { Carousel } from "antd";
import { TypeAnimation } from "react-type-animation";
import { useHistory } from "react-router-dom";

const Landing = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/remote-sensing"); // Navigate to '/remote-sensing'
  };
  const handleClick2 = () => {
    history.push("/identity-management");
  };
  return (
    <Row className="background-landing" style={{ backgroundColor: "black" }}>
      {/* <Col
        className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-end justify-content-center"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "20px",
        }}
      > */}

      <Col
        className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-end justify-content-center"
        style={{
          position: "relative", // Ensure the positioning context for absolute positioning
          // padding: "20px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={backgroundImg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Link
          to="/remote-sensing"
          className="link-wrapper"
          style={{
            textDecoration: "none",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 2,
            width: "100%",
          }}
        >
          <Carousel autoplay></Carousel>
          <div className="black-translucent-card">
            <Heading
              lightText={true}
              class="display-1"
              style={{ textAlign: "end" }}
            >
              SPACE
            </Heading>
          </div>
        </Link>
      </Col>

      <Col
        className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-end justify-content-center"
        style={{
          position: "relative", // Ensure the positioning context for absolute positioning
          // padding: "20px",
          cursor: "pointer",
        }}
        onClick={handleClick2}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={backgroundImg2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* Wrap the content with Link */}
        <Link
          to="/identity-management"
          className="link-wrapper"
          style={{
            textDecoration: "none",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 2,
            width: "100%",
          }}
        >
          <div className="black-translucent-card">
            <Heading
              lightText={true}
              class="display-1"
              style={{
                alignItems: "right",
                textAlign: "end",
              }}
            >
              IDENTITY
            </Heading>
          </div>
        </Link>
      </Col>
    </Row>
  );
};

export default Landing;
