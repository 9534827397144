import styled from "styled-components";

export const VacancyBg = styled.div`
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url("/vacancy.png");
  background-repeat: no-repeat;
  background-size: 800px;
  background-position: left;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 50px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 50px 0;
  }
`;
