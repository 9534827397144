import React from "react";
import {
  InfoSecThree,
  InfoRow,
  InfoColumnThree,
  ImgColumnThree,
  ImgColumn,
  TextWrapperThree,
  TopLine,
  Heading,
  SubtitleFull,
  SubtitleFull2,
  Img,
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";
import { Divider, Space } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CustomBullet from "../Bullet/bullet";
import ServiceCard from "../ServiceCard/ServiceCard";
import logo1 from "../../images/datacopy-1@2x.png";
import logo2 from "../../images/saas-2@2x.png";
import logo3 from "../../images/user-1@2x.png";
import logo4 from "../../images/biometricidentification-1@2x.png";

const InfoSectionThree = ({
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
}) => {
  const isLargeScreen = window.innerWidth >= 480;
  return (
    <>
      <InfoSecThree>
        <Container>
          <InfoRow imgStart={imgStart}>
            <Row style={{ paddingTop: "50px" }}>
              <Col xs={12} sm={12} md={12} lg={6}>
                <TextWrapperThree>
                  <TopLine lightTopLine={lightTopLine}>
                    {isLargeScreen ? (
                      <Row justify="end">
                        <Col xs={4} sm={4} md={3} lg={3}>
                          <Divider
                            orientation="left"
                            style={{
                              borderTop: "3px solid red",
                            }}
                          />
                        </Col>
                        <Col
                          xs={9}
                          sm={9}
                          md={9}
                          lg={9}
                          style={{ marginTop: "12px" }}
                        >
                          {topLine}
                        </Col>
                      </Row>
                    ) : (
                      <TopLine lightTopLine={lightTopLine}>
                        <Row>
                          <Col
                            xs={10}
                            sm={10}
                            md={5}
                            lg={5}
                            style={{ marginTop: "12px" }}
                          >
                            {topLine}
                          </Col>
                          <Col xs={4} sm={4} md={2} lg={2}>
                            <Divider
                              orientation="left"
                              style={{ borderTop: "3px solid red" }}
                            />
                          </Col>
                        </Row>
                      </TopLine>
                    )}
                  </TopLine>

                  <Heading lightText={lightText}>{headline}</Heading>
                </TextWrapperThree>
                <SubtitleFull2 lightTextDesc={lightTextDesc}>
                  {description}
                </SubtitleFull2>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Row>
                  <Space direction="vertical" size={20}>
                    <Row gutter={20}>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <ServiceCard
                          serviceName="DE-DUPLICATION AS A SERVICE"
                          imgSrc={logo1}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <ServiceCard
                          serviceName="SOFTWARE AS A SERVICE"
                          imgSrc={logo2}
                        />
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <ServiceCard
                          serviceName="ENROLLMENT AS A SERVICE"
                          imgSrc={logo3}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <ServiceCard
                          serviceName="BIOMETRICS TRAINING"
                          imgSrc={logo4}
                        />
                      </Col>
                    </Row>
                  </Space>
                </Row>
              </Col>
            </Row>
          </InfoRow>
        </Container>
      </InfoSecThree>
    </>
  );
};

export default InfoSectionThree;
