import React from "react";
import ImageSection from "../../components/OurProjectsSection/ImageSection";
import OurProjectsDescription from "../../components/OurProjectsSection/OurProjectsDescription";
import ProjectCardsSection from "../../components/OurProjectsSection/ProjectCardsSection";
import { homeObjOne, projectsList } from "./Data";
import { Helmet } from "react-helmet";
import { Footer } from "../../components";

function Projects() {
  return (
    <>
      <Helmet>
        <title>Biosec Solutions | biosec.com.ng</title>
        <meta name="description" content="secure innovative solutions. " />
        <meta
          name="description"
          content="NIMC FEP, NIMC licensed service provider, NIN registration, National Identity Number NIN. "
        />
        <meta
          name="description"
          content="Digital Identity Ecosystem, digital identities in Nigeria. "
        />
        <meta
          name="description"
          content="biometric identifiers, enterprise PKI. "
        />
        <meta
          name="description"
          content="NIMC, NPF, identity verification challenges in Nigeria. "
        />
        <meta
          name="description"
          content="Violence Against Persons Prohibition Act."
        />
        {/* Add other meta tags as needed */}
      </Helmet>
      <ImageSection />
      <OurProjectsDescription {...homeObjOne} />
      <ProjectCardsSection {...projectsList} />
      <Footer />
    </>
  );
}

export default Projects;
