import React from "react";
import Icon from "@ant-design/icons";
import { Container, DarkButton } from "../../globalStyles";
import { Item } from "../CustomBg/CustomBg.elements";
import { Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { Collapse } from "antd";
import ItemComponent from "./ItemComponent";

const items = [
  {
    key: "1",
    label: <h5>VERIEYE 13.0 STANDARD SDK</h5>,
    children: (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent
            name="VeriEye 13.0 Standard SDK"
            src="/eye.png"
            srcModal="/eye.png"
            details="VeriEye iris identification technology is designed to include many proprietary solutions that enable robust eye iris enrollment under various conditions and fast iris matching in 1-to-1 and 1-to-many modes. Engineered with capabilities to implement advanced iris segmentation, enrollment and matching including Robust eye iris detection even when there are obstructions to the image, visual noise and/or different levels of illumination. Gazing-away eyes are correctly detected, segmented and transformed as if looking directly into the camera. Automatic interlacing detection and correction results in maximum quality of iris features templates from moving iris images. Eyelids, which partially occlude an iris, are detected and segmented from the iris image. VeriEye is available as a software development kit that allows development of stand-alone and Web-based solutions on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms. VeriEye Standard SDK is designed for PC-based, embedded and mobile biometric application development. It includes Iris Matcher and Extractor component licenses, programming samples and tutorials, iris scanner support modules and software documentation"
          />
        </Col>
      </Row>
    ),
  },
  {
    key: "2",
    label: <h5>VERIEYE EXTENDED SDKS</h5>,
    children: (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="VeriEye 13.0 Extended SDK"
              src="/eye.png"
              srcModal="/eye.png"
              details="VeriEye iris identification technology is designed to include many proprietary solutions that enable robust eye iris enrollment under various conditions and fast iris matching in 1-to-1 and 1-to-many modes. Engineered with capabilities to implement advanced iris segmentation, enrollment and matching including Robust eye iris detection even when there are obstructions to the image, visual noise and/or different levels of illumination. Gazing-away eyes are correctly detected, segmented and transformed as if looking directly into the camera. Automatic interlacing detection and correction results in maximum quality of iris features templates from moving iris images. Eyelids, which partially occlude an iris, are detected and segmented from the iris image. VeriEye is available as a software development kit that allows development of stand-alone and Web-based solutions on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms. VeriEye Standard SDK is designed for PC-based, embedded and mobile biometric application development. It includes Iris Matcher and Extractor component licenses, programming samples and tutorials, iris scanner support modules and software documentation"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Iris Client component license(s)"
              src="/eye.png"
              srcModal="/eye.png"
              details="VeriEye iris identification technology is designed to include many proprietary solutions that enable robust eye iris enrollment under various conditions and fast iris matching in 1-to-1 and 1-to-many modes. Engineered with capabilities to implement advanced iris segmentation, enrollment and matching including Robust eye iris detection even when there are obstructions to the image, visual noise and/or different levels of illumination. Gazing-away eyes are correctly detected, segmented and transformed as if looking directly into the camera. Automatic interlacing detection and correction results in maximum quality of iris features templates from moving iris images. Eyelids, which partially occlude an iris, are detected and segmented from the iris image. VeriEye is available as a software development kit that allows development of stand-alone and Web-based solutions on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms. VeriEye Standard SDK is designed for PC-based, embedded and mobile biometric application development. It includes Iris Matcher and Extractor component licenses, programming samples and tutorials, iris scanner support modules and software documentation"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Iris Extractor component license(s)"
              src="/eye.png"
              srcModal="/eye.png"
              details="VeriEye iris identification technology is designed to include many proprietary solutions that enable robust eye iris enrollment under various conditions and fast iris matching in 1-to-1 and 1-to-many modes. Engineered with capabilities to implement advanced iris segmentation, enrollment and matching including Robust eye iris detection even when there are obstructions to the image, visual noise and/or different levels of illumination. Gazing-away eyes are correctly detected, segmented and transformed as if looking directly into the camera. Automatic interlacing detection and correction results in maximum quality of iris features templates from moving iris images. Eyelids, which partially occlude an iris, are detected and segmented from the iris image. VeriEye is available as a software development kit that allows development of stand-alone and Web-based solutions on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms. VeriEye Standard SDK is designed for PC-based, embedded and mobile biometric application development. It includes Iris Matcher and Extractor component licenses, programming samples and tutorials, iris scanner support modules and software documentation"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <ItemComponent
              name="Additional Iris Matcher component license(s)"
              src="/eye.png"
              srcModal="/eye.png"
              details="VeriEye iris identification technology is designed to include many proprietary solutions that enable robust eye iris enrollment under various conditions and fast iris matching in 1-to-1 and 1-to-many modes. Engineered with capabilities to implement advanced iris segmentation, enrollment and matching including Robust eye iris detection even when there are obstructions to the image, visual noise and/or different levels of illumination. Gazing-away eyes are correctly detected, segmented and transformed as if looking directly into the camera. Automatic interlacing detection and correction results in maximum quality of iris features templates from moving iris images. Eyelids, which partially occlude an iris, are detected and segmented from the iris image. VeriEye is available as a software development kit that allows development of stand-alone and Web-based solutions on Microsoft Windows, Linux, Mac OS X, iOS and Android platforms. VeriEye Standard SDK is designed for PC-based, embedded and mobile biometric application development. It includes Iris Matcher and Extractor component licenses, programming samples and tutorials, iris scanner support modules and software documentation"
            />
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "3",
    label: <h5>SENTISIGHT SDKS</h5>,
    children: (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent
            name="Sentisight SDK"
            src="/eye.png"
            srcModal="/eye.png"
            details="SentiSight is designed to be as universal as possible and is able to perform fully automatic and manual object learning. SentiSight has two operation modes: learning and recognition. In learning mode, the SentiSight algorithm creates an object model by extracting object features from an image or video. In recognition mode, SentiSight finds and tracks objects with features matching those previously stored in object models. SentiSight is designed to be as universal as possible and is able to perform fully automatic or manual object learning. The technology can be used for a wide range of tasks, including: Some of the potential applications for SentiSight include security systems, vision systems for robots, machine vision (like parts recognition in production lines), search engines that recognize objects in picture files, road signs recognition, etc. Recognition of documents, stamps, labels, packaging and other items for sorting, logo masking, usage monitoring and similar applications. Object counting and inspection for assembly lines and other industrial applications Augmented and extended reality applications for toys, games, devices and Web applications such as: smart toys for children that recognize cards, images, pictograms, etc.; recognition of places based on photographs; recognition of products such as beverages, foods and other consumer goods. Robotic vision for navigation and manipulation, Law enforcement applications for identification, such as tattoo recognition."
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <ItemComponent
            name="Additional SentiSight 3.x installation license(s)"
            src="/eye.png"
            srcModal="/eye.png"
            details="SentiSight is designed to be as universal as possible and is able to perform fully automatic and manual object learning. SentiSight has two operation modes: learning and recognition. In learning mode, the SentiSight algorithm creates an object model by extracting object features from an image or video. In recognition mode, SentiSight finds and tracks objects with features matching those previously stored in object models. SentiSight is designed to be as universal as possible and is able to perform fully automatic or manual object learning. The technology can be used for a wide range of tasks, including: Some of the potential applications for SentiSight include security systems, vision systems for robots, machine vision (like parts recognition in production lines), search engines that recognize objects in picture files, road signs recognition, etc. Recognition of documents, stamps, labels, packaging and other items for sorting, logo masking, usage monitoring and similar applications. Object counting and inspection for assembly lines and other industrial applications Augmented and extended reality applications for toys, games, devices and Web applications such as: smart toys for children that recognize cards, images, pictograms, etc.; recognition of places based on photographs; recognition of products such as beverages, foods and other consumer goods. Robotic vision for navigation and manipulation, Law enforcement applications for identification, such as tattoo recognition."
          />
        </Col>
      </Row>
    ),
  },
];

function Eye() {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Collapse items={items} defaultActiveKey={["1"]} />
    </Container>
  );
}

export default Eye;
