export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "OUR PROJECTS",
  headline:
    "A COLLECTION OF INNOVATIVE AND IMPACTFUL IDENTITY MANAGEMENT PROJECTS ",
  description:
    "In partnership with National Identity Management Commission (NIMC), Nigeria Police Force (NPF), and other top organizations, we have created unique projects to aid in combating identity verification challenges in Nigeria. ",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "100% Secure",
  headline: "Stay protected 24/7 anywhere anytime",
  description:
    "We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime",
  buttonLabel: "Learn More",
  imgStart: "start",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
};

const projectOne = {
  title: "NAPTIP NATIONAL SEXUAL OFFENDERS SYSTEM/DATABASE",
  description:
    "The sexual offender register documents reported, arraigned, or convicted sex abuse cases in Nigeria under the Violence Against Persons Prohibition Act (VAPP) 2015. It is accessible to the public and security agencies, with convicted offenders’ data available on the website and other categories upon demand. ",
  hasButton: false,
  buttonLabel: "",
  img: require("../../images/naptip-logo.png"),
  alt: "Vault",
};

const projectTwo = {
  title: "NIMC DIASPORA ENROLMENT",
  description:
    "We are a front-end partner (FEP) and licensed service provider of the National Identity Management Commission (NIMC). We offer National Identity Number (NIN) registration to Nigerians worldwide. Contact us to become an accredited NIMC agent for NIN enrolment in Nigeria or the diaspora. ",
  hasButton: true,
  buttonLabel: "ENROLL NOW!",
  img: require("../../images/NIMC-img.png"),
  alt: "Vault",
};

const projectThree = {
  title: "POLICE BIOMETRIC CRIME AND INCIDENT MANAGEMENT SYSTEM",
  description:
    "We proudly designed, implemented, and support the Nigeria Police Force (NPF) Crime and Incident Management System, which digitized manual processes, propelling the Nigerian police into a data-driven, intelligence-based e-force. ",
  hasButton: false,
  buttonLabel: "",
  img: require("../../images/police-biometric-logo.png"),
  alt: "Vault",
};

const projectFour = {
  title: "NIMC ABIS SUPPORT AND MAINTENANCE ",
  description:
    "We pride ourselves as the Nigerian technology partners of the NIMC Automated Biometric Identification System (ABIS). We work within the Digital Identity Ecosystem to ensure that the national identity adjudicator is highly available and accurate in ensuring the uniqueness of digital identities in Nigeria. ",
  hasButton: false,
  buttonLabel: "",
  img: require("../../images/NIMC-img.png"),
  alt: "Vault",
};

const projectFive = {
  title: "NIMC E-ID DATA PREPARATION ",
  description:
    "Our solution provides e-ID data preparation for the National Electronic Identity Card (e-ID card) and facilitates the chip and card body personalization of biometric identifiers (fingerprint, face, iris), demographics, meta data, and Enterprise PKI (ePKI) data for Nigerians",
  hasButton: false,
  buttonLabel: "",
  img: require("../../images/NIMC-img.png"),
  alt: "Vault",
};

export const projectsList = [
  projectOne,
  projectTwo,
  projectThree,
  projectFour,
  projectFive,
];
