import styled from 'styled-components';

export const CustomBgSec = styled.div`
  background-image: url('/print_bg.svg');
  background-repeat: no-repeat;
  background-size: 950px;
  background-position: right;
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? '#282930' : '#282930')};
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const TextWrapper = styled.div`
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;
  }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Screenshot20230621151140RIcon = styled.img`
  position: relative;
  width: 50px;
  height: 51px;
  object-fit: cover;

  @media (min-width: 480px) and (max-width: 1200px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Item = styled.img`
  width: 100%;
  height: 301px;

  @media (min-width: 480px) and (max-width: 1200px) {
     display: block;
  margin-left: auto;
  margin-right: auto;
  }

  @media (min-width: 320px) and (max-width: 480px) {
     display: block;
  margin-left: auto;
  margin-right: auto;
  }

`;

export const Screenshot20230621151140RIconStory = styled.img`
  position: relative;
  width: 100px;
  height: 31px;

  @media (min-width: 480px) and (max-width: 1200px) {
     display: block;
  margin-left: auto;
  margin-right: auto;
  }

  @media (min-width: 320px) and (max-width: 480px) {
     display: block;
  margin-left: auto;
  margin-right: auto;
  }

`;

export const HeadingMedium = styled.h3`
  margin-bottom: 24px;
  font-family: 'Montserrat', sans-serif;
font-size: 25px;
font-weight: 300;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#292F6F')};

  @media (min-width: 480px) and (max-width: 1200px) {
    font-size: 25px;
    text-align: center;
    font-weight: 300;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 25px;
    text-align: center;
    font-weight: 300;
  }
`;

export const Cpu1Parent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
`;
