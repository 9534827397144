import styled from "styled-components";

export const CustomBgSec = styled.div`
 
background-image: url("/fingerprint.png");
background-repeat: no-repeat;
background-size: cover;
`;

export const CustomBgSecStory = styled.div`
 
background-image: url("/wall.png");
background-repeat: no-repeat;
background-size: cover;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 530px;
`;