import styled from "styled-components";

export const NewsletterSec = styled.div`
  color: #fff;
  padding: 80px 0;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 0px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0px 0;
  }
`;

export const NewsletterRow = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  background-image: url("/news_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) => (primary ? "#292F6F" : "#292F6F")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #292f6f;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #ffffff;
    background-color: ${({ primary }) => (primary ? "#292F6F" : "#4B59F7")};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const SubscribeToOurNewslettersParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
`;

export const SubscribeToOur = styled.div`
  position: relative;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  font-size: 27px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
`;

export const BeTheFirst = styled.p`
  margin: 0;
`;
export const BeTheFirstContainer = styled.div`
  position: relative;
  color: #ffffff;
  margin-bottom: 10px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
`;
