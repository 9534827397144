import { Link } from "react-router-dom";
import styled from "styled-components";

export const PricingSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #4b59f7;
`;

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PricingHeading = styled.h1`
  color: #fff;
  font-size: 48px;
  margin-bottom: 24px;
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PricingCard = styled(Link)`
  background: #fafaff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  border-top: 7px solid red;
  width: 307px;
  height: 307px;
  text-decoration: none;
  margin-top: 30px;
  border-radius: 12px;
  &:nth-child(2) {
    margin: 24px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    &:hover {
      transform: none;
    }
  }
`;

export const PricingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 307px;
  padding: 24px;
  align-items: left;
  color: #282930;
`;

export const PricingCardIcon = styled.div`
  margin: 24px 0;
`;

export const PricingCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 24px;
`;

export const PricingCardCost = styled.h4`
  font-family: "Montserrat", sans-serif;
  color: ${({ lightText }) => (lightText ? "#fff" : "#282930")};
  leading-trim: both;
  text-edge: cap;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  @media (min-width: 480px) and (max-width: 1200px) {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
  }
`;

export const PricingCardLength = styled.p`
  margin-bottom: 24px;
`;

export const PricingCardFeatures = styled.ul`
  margin: 26px 0 32px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #292f6f;
`;

export const PricingCardFeature = styled.li`
  margin-bottom: 10px;
  font-weight: 400;
`;
