import React, { useState } from "react";
import Icon from "@ant-design/icons";
import { DarkButton } from "../../globalStyles";
import { Item } from "../CustomBg/CustomBg.elements";
import { Col, Row, Divider, Modal } from "antd";
import { Link } from "react-router-dom";

function ItemComponent(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Row>
      <p onClick={showModal}>
        <Item alt="" src={props.src} />
        <h6 style={{ color: "black", textDecoration: "none" }}>{props.name}</h6>
        <Link to="/contact">
          <DarkButton style={{ width: "260px", color: "#fff" }}>
            Get in touch
          </DarkButton>
        </Link>
      </p>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        centered
        footer={null}
        width={900}
        onCancel={handleCancel}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <Item alt="" src={props.srcModal} />
          </Col>
          <Col xs={24} sm={24} md={10} lg={10}>
            <p>{props.details}</p>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}

export default ItemComponent;
