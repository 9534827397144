import React from 'react';
import { PlainText } from '../../globalStyles';
import { StyledServiceCard } from './DetailedServiceCard.elements';
import { Img } from '../InfoSection/InfoSection.elements';
import Meta from 'antd/es/card/Meta';

const DetailedServiceCard = ({ title, description, img }) => {
  return (
    <StyledServiceCard cover={<Img alt='service imge' src={img} />}>
      <Meta
        title={
          <PlainText blue large>
            {title}
          </PlainText>
        }
        description={
          <PlainText dark style={{ textAlign: 'justify' }}>
            {description}
          </PlainText>
        }
      />
    </StyledServiceCard>
  );
};

export default DetailedServiceCard;
