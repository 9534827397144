import React from "react";
import { Container } from "../../globalStyles";
import { Col, Row, Divider } from "antd";
import DetailedServiceCard from "../DetailedServiceCard/DetailedServiceCard";
import { TopLine } from "../CustomBg/CustomBg.elements";
import { CustomBgSec, Img } from "./Timelines.elements";

const TimelinesSection = ({ img }) => {
  return (
    <>
      <CustomBgSec>
        <Container style={{ paddingTop: "50px", paddingBottom: "90px" }}>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <TopLine style={{ textAlign: "center" }}>
                <Row justify="center">
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{ marginTop: "16px" }}
                  >
                    OUR TIMELINE
                  </Col>
                  <Col xs={4} sm={4} md={6} lg={6}>
                    <Divider
                      orientation="left"
                      style={{ borderTop: "3px solid red" }}
                    />
                  </Col>
                </Row>
              </TopLine>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Img src={img} alt={"alt"} />
            </Col>
          </Row>
        </Container>
      </CustomBgSec>
    </>
  );
};

export default TimelinesSection;
