import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//img
import headerImg from "../../../assets/img/Images-03.jpg";
import "./GridComponent.css"; // CSS file for styling
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const Header = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "100vh", // Changed minHeight to 100vh
    display: "flex",
    justifyContent: "center",
    // tamanhos
    gap: theme.spacing(2),
    paddingTop: theme.spacing(10),
    // cor de fundo
    backgroundColor: "#060B12",
    backgroundImage: `url(${headerImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    paddingTop: "100px",
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      {/*  Box text  */}
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="black-translucent-card2">
          <BoxText component="section">
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: 700,
                color: "#fff",
                width: "auto",
              }}
            >
              SPACE
            </Typography>

            <Typography
              variant="p"
              component="p"
              sx={{
                py: 3,
                lineHeight: 1.6,
                color: "#fff",

                fontSize: "20px",
              }}
            >
              Biosec Solutions stands out in the African market for its ability
              to harness the power of existing satellite data without the need
              for deploying new satellites. This approach not only makes our
              solutions more cost-effective but also accelerates the
              implementation of our services.
            </Typography>

            <Box>
              <Button
                component={Link}
                to={"/contact"}
                variant="outlined"
                sx={{
                  px: 4,
                  py: 1,
                  fontSize: "0.9rem",
                  textTransform: "capitalize",
                  borderRadius: 0,
                  color: "#fff",
                  backgroundColor: "transparent",
                  borderColor: "#fff",
                  "&&:hover": {
                    color: "#343a55",
                    borderColor: "#343a55",
                  },
                  "&&:focus": {
                    color: "#343a55",
                    borderColor: "#343a55",
                  },
                }}
              >
                explore
              </Button>
            </Box>
          </BoxText>
        </div>
      </div>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            flex: "1",
            paddingTop: "30px",
            alignSelf: "center",
          },
          [theme.breakpoints.up("md")]: {
            flex: "2",
            alignSelf: "flex-end",
          },
        })}
      >
        {/* Image removed */}
      </Box>
    </CustomBox>
  );
};

export default Header;
