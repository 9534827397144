import React from "react";
import "./GridComponent.css"; // CSS file for styling
import {
  Heading,
  Subtitle,
} from "../../../components/HeroSection/HeroSection.elements";

const GridComponent = ({ imageUrl, text, subtext }) => {
  const gridStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="grid-container" style={gridStyle}>
      <div className="text-overlay">
        <div className="black-translucent-card" style={{ padding: "60px" }}>
          <Heading
            lightText={true}
            class="display-1"
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {text}
          </Heading>
          <p className="sub-text" style={{ width: "auto", fontSize: "20px" }}>
            {subtext}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GridComponent;
