import React from "react";
import { DarkButton } from "../../globalStyles";
import {
  NewsletterSec,
  SubscribeToOurNewslettersParent,
  SubscribeToOur,
  BeTheFirstContainer,
  BeTheFirst,
  NewsletterRow,
} from "./NewsletterSection.elements";
import { Col, Form, Input, Row } from "antd";

const NewsletterSection = ({ primary }) => {
  return (
    <>
      <NewsletterSec>
        <NewsletterRow>
          <SubscribeToOurNewslettersParent>
            <SubscribeToOur>SUBSCRIBE TO OUR NEWSLETTER </SubscribeToOur>
            <BeTheFirstContainer>
              <BeTheFirst>
                Unlock access to up-to-date industry news and information.
              </BeTheFirst>
            </BeTheFirstContainer>
          </SubscribeToOurNewslettersParent>
          <Form size="large" name="basic" autoComplete="off">
            <Row justify="center" gutter={16}>
              <Col xs={24} sm={24} md={15} lg={15}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your email address" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <DarkButton
                    big
                    primary={primary}
                    htmlType="submit"
                    style={{ color: "white" }}
                  >
                    Subscribe
                  </DarkButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </NewsletterRow>
      </NewsletterSec>
    </>
  );
};
export default NewsletterSection;
