import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
// img
import imgDetail from "../../../assets/img/Images-09.jpg";
import imgDetail2 from "../../../assets/img/Images-04.jpg";

const GetStarted = () => {
  const CustomGridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 0, // Remove top and bottom paddings
      }}
    >
      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        component="section"
        style={{ backgroundColor: "#0A0C0D", color: "#FFF" }}
      >
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title text={"COMMUNICATION ENHANCEMENT"} textAlign={"start"} />
          <CustomTypography style={{ color: "#FFF" }}>
            We focus on improving communications in remote areas, ensuring
            better connectivity and access to information for underserved
            communities,
          </CustomTypography>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail}
          alt=""
          style={{
            width: "100%",
            height: "400px",
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        sx={{
          order: { xs: 4, sm: 4, md: 3 },
        }}
      >
        <img
          src={imgDetail2}
          alt=""
          style={{
            width: "100%",
            height: "400px",
          }}
        />
      </Grid>

      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        sx={{
          order: { xs: 3, sm: 3, md: 4 },
        }}
        style={{ backgroundColor: "#0A0C0D", color: "#FFF" }}
      >
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title text={"AGRICULTURAL DEVELOPMENT"} textAlign={"start"} />
          <CustomTypography style={{ color: "#FFF" }}>
            Our use of NDVI (Normalized Differente Vegetation Index) and Red
            Edge Indices, which are sensitive to chlorophyll, allows for precise
            monitoring of crop health. This is pivotal for both annual crops
            like maize and wheat. We also specialize in measuring key soil
            nutrients like potassium, essential for crop management and yield
            optimization.
          </CustomTypography>
        </Box>
      </CustomGridItem>
    </Grid>
  );
};

export default GetStarted;
