import React from "react";
import ServicesBgSection from "../../components/ServicesSection/ServicesBgSection";
import EcitizenSection from "../../components/ServicesSection/EcitizenSection";
import OurSolutionsSection from "../../components/ServicesSection/OurSolutionsSection";
import { Helmet } from "react-helmet";
import BiosuiteSection from "../../components/ServicesSection/BiosuiteSection";
import { Footer } from "../../components";

function Solutions() {
  return (
    <>
      <Helmet>
        <title>Biosec Solutions | biosec.com.ng</title>
        <meta
          name="description"
          content="zero trust, biometrics technology, biometrics security solutions. "
        />
        <meta
          name="description"
          content="digital ID verification, verify Nigerian employees, verify Nigerian identities, identity theft, digital fraud."
        />
        <meta
          name="description"
          content="authentication solutions, one-to-one verification, one-to-many identification. "
        />
        <meta name="description" content="data deduplication. " />
        <meta name="description" content="cloud storage, on-demand software." />
        <meta name="description" content="enrolment software. " />
        <meta
          name="description"
          content="ethical biometrics, biometrics training in Nigeria, cybersecurity. "
        />

        {/* Add other meta tags as needed */}
      </Helmet>
      <OurSolutionsSection />
      <EcitizenSection />
      <BiosuiteSection />
      <Footer />
    </>
  );
}

export default Solutions;
