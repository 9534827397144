import React from "react";
import { Container } from "../../globalStyles";
import { Space } from "antd";
import {
  InfoRow,
  Heading,
  SubtitleFull,
  Img,
  ImgColumn,
  TextWrapper,
  InfoColumn,
} from "../InfoSection/InfoSection.elements";
import ecitizenImg from "../../images/groupman.svg";
import ecitizen_logo from "../../images/ecitizen logo.svg";

const EcitizenSection = () => {
  return (
    <>
      <Container>
        <InfoRow imgStart={true} style={{ paddingTop: "50px" }}>
          <InfoColumn>
            <TextWrapper>
              <Space direction="vertical" size={20}>
                <Heading>
                  <a href="https://e-citizen.ng" target="_blank">
                    {" "}
                    <Img src={ecitizen_logo} />
                  </a>
                </Heading>
                <SubtitleFull large style={{ marginBottom: 10 }}>
                  VERIFY THE IDENTITY OF ANY NIGERIAN OR LEGAL RESIDENT IN
                  SECONDS WITH OUR ONLINE IDENTITY VERIFICATION SOLUTION.
                </SubtitleFull>
                <SubtitleFull style={{ textAlign: "justify" }}>
                  ecitizen™ helps you verify the identities of your Nigerian
                  employees and clients fast and seamlessly.  This convenient
                  platform not only provides a safe and efficient method for
                  accessing important government services, such as filing taxes
                  and verifying your driver’s license, it also eases the process
                  for you with a user-friendly and easy-to-use platform. Trust
                  e-citizen™ for a secure and streamlined experience in
                  accessing government services. Prevent digital fraud and
                  identity theft with fast digital ID verification.
                </SubtitleFull>
              </Space>
            </TextWrapper>
          </InfoColumn>
          <ImgColumn>
            <Img src={ecitizenImg} alt="ecitizen" />
          </ImgColumn>
        </InfoRow>
      </Container>
    </>
  );
};

export default EcitizenSection;
