export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Marketing Agency",
  headline: "WELCOME TO THE FUTURE OF IDENTITY MANAGEMENT ",
  description:
    "As forerunners in the identity management industry in Nigeria, our core strengths lie in biometrics, smart card technology, and token-based authentication. Biosec Solutions is your partner in shaping the future of IAM: Identity and Access Management in Nigeria and Africa at large.",
  buttonLabel: "Get In Touch",
  imgStart: "",
  // img: require('../../images/svg-1.svg'),
  // alt: 'Credit Card',
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "WHAT WE OFFER",
  headline: "WE OFFER DIVERSE CUSTOMER-TAILORED SERVICES ",
  description:
    "At Biosec Solutions, we understand that one size does not fit all. Whether you're a small business or a large enterprise, we offer unique-tailored identity management solutions to meet your needs and expectations. Discover the Biosec advantage with our personalized services. From identity management to Enterprise Architecture (EA) software, we are here to serve your every requirement.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "WHO WE ARE ",
  headline: "WE ARE A TRUSTED IDENTITY MANAGEMENT SOLUTIONS PROVIDER. ",
  description: `Biosec Solutions specializes in providing cutting-edge identity management, authentication and verification solutions.`,
  description2: `Keep your business secure and in compliance with KYC (Know-Your-Customer), KYE (Know-Your-Employee), and AML (Anti-Money Laundering) regulations with our uniquely tailored identity management and verification software solutions.`,
  buttonLabel: "View Case Study",
  imgStart: "start",
  img: require("../../assets/img/front.jpg"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  headline: "OUR CLIENTS AND AFFILIATES",
  description:
    "We are committed to offering innovative biometric solutions in Nigeria through the use of emerging technologies. This is why we invest in strategic partnerships with the most important high-tech companies and organizations.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/clients.svg"),
  alt: "Vault",
  start: "true",
};
