import React, { useState } from "react";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  ImgColumn,
  TextWrapper,
  Heading,
  SubtitleShort,
  ImgGuard,
  Img,
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";

import "./Imagemap.css";
import ImageMapper from "react-image-mapper";

const InfoSectionTwo = ({
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
}) => {
  const [hoveredArea, setHoveredArea] = useState(null);

  const enterArea = (area) => {
    setHoveredArea(area);
  };

  const leaveArea = () => {
    setHoveredArea(null);
  };

  const getTipPosition = (area) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  };

  const clicked = (area) => {
    // Handle click event on an area if needed
    console.log(`Clicked area: ${area.name}`);
    if (area.url) {
      window.open(area.url, "_blank");
    }
  };

  const clickedOutside = () => {
    // Handle click event outside the image if needed
    console.log("Clicked outside the image");
  };

  const moveOnArea = (area, evt) => {
    // Handle mouse move event on an area if needed
    console.log(`Mouse move on area: ${area.name}`);
  };

  const moveOnImage = () => {
    // Handle mouse move event on the image if needed
    console.log("Mouse move on image");
  };

  const MAP = {
    name: "my-map",
    areas: [
      {
        name: "NIGERIAN CUSTOM",
        shape: "circle",
        coords: [60, 175, 47],
        url: "https://customs.gov.ng/",
      },
      {
        name: "NEUROTECHNOLOGY",
        shape: "circle",
        coords: [60, 320, 47],
        url: "https://www.neurotechnology.com",
      },
      {
        name: "STARLINK",
        shape: "circle",
        coords: [150, 360, 37],
        url: "https://www.starlink.com/",
      },
      {
        name: "FEDERAL MINISTRY OF TRADE & INVESTMENT",
        shape: "circle",
        coords: [180, 255, 37],
        url: "http://nid.fmiti.gov.ng/",
      },
      {
        name: "INTEGRATED BIOMETRICS",
        shape: "circle",
        coords: [220, 440, 47],
        url: "https://integratedbiometrics.com/",
      },
      {
        name: "INEC",
        shape: "circle",
        coords: [420, 95, 40],
        url: "https://www.inecnigeria.org/",
      },
      {
        name: "OSIA",
        shape: "circle",
        coords: [400, 175, 30],
        url: "https://secureidentityalliance.org/osia",
      },
      {
        name: "id4d",
        shape: "circle",
        coords: [450, 255, 40],
        url: "https://td4pai.org.ng/",
      },
      {
        name: "BIOMETRIC INSTITUTE",
        shape: "circle",
        coords: [410, 395, 50],
        url: "https://www.biometricsinstitute.org/",
      },
      {
        name: "THE NIGERIAN POLICE",
        shape: "circle",
        coords: [280, 325, 35],
        url: "https://www.npf.gov.ng/",
      },
      {
        name: "NAPTIP",
        shape: "circle",
        coords: [340, 255, 30],
        url: "https://naptip.gov.ng/",
      },
      {
        name: "NIMC",
        shape: "circle",
        coords: [260, 155, 47],
        url: "https://nimc.gov.ng/",
      },
      {
        name: "IDEMIA",
        shape: "circle",
        coords: [235, 45, 35],
        url: "https://www.idemia.com/",
      },
      {
        name: "NDPR",
        shape: "circle",
        coords: [165, 115, 35],
        url: "https://osgf.gov.ng/storage/app/media/nigeria-data-protection-regulation-ndpr.pdf",
      },
    ],
  };

  const isLargeScreen = window.innerWidth >= 480;
  return (
    <>
      <InfoSec>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper style={{ paddingTop: "30px" }}>
                <Heading lightText={lightText}>{headline}</Heading>
                <SubtitleShort lightTextDesc={lightTextDesc}>
                  {description}
                </SubtitleShort>
              </TextWrapper>
            </InfoColumn>
            <ImgColumn className="container">
              {isLargeScreen ? (
                <ImageMapper
                  src={img}
                  map={MAP}
                  width={500}
                  onLoad={() => {}}
                  onClick={(area) => clicked(area)}
                  onMouseEnter={(area) => enterArea(area)}
                  onMouseLeave={() => leaveArea()}
                  onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
                  onImageClick={() => clickedOutside()}
                  onImageMouseMove={() => moveOnImage()}
                />
              ) : (
                <Img src={img} alt={alt} />
              )}

              {hoveredArea && (
                <span
                  className="tooltip"
                  style={{ ...getTipPosition(hoveredArea) }}
                >
                  {hoveredArea.name}
                </span>
              )}
            </ImgColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default InfoSectionTwo;
