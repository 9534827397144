import React from "react";
import {
  InfoSection,
  HeroSection,
  InfoSectionThree,
  Footer,
} from "../../components";
import { homeObjOne, homeObjThree, homeObjTwo } from "./Data";
import NewsletterSection from "../../components/Newsletter/NewsletterSection";
import CustomBg from "../../components/CustomBg/CustomBg";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Biosec Solutions | biosec.com.ng</title>
        <meta
          name="description"
          content="IAM: Identity and access management in Nigeria, IAM: Identity and access management in Africa, identity management industry, Identity and Access Management, token-based authentication. "
        />
        <meta
          name="description"
          content="IAM: identity and access management, verification solutions, NIMC verification services, NIN verification services, biometric authentication, identity verification, NIMC diaspora enrolment, NVS. "
        />
        <meta
          name="description"
          content="Enterprise Architecture (EA) software. "
        />
        <meta
          name="description"
          content="biometric solutions in Nigeria, cybersecurity technologies in Nigeria."
        />
        <meta
          name="description"
          content="biometric solutions in Nigeria, ID verification, token-based authentication, identity management challenges in Africa. "
        />

        {/* Add other meta tags as needed */}
      </Helmet>
      <HeroSection {...homeObjOne} />
      <InfoSection {...homeObjThree} />
      <InfoSectionThree {...homeObjTwo} />
      <CustomBg />
      <NewsletterSection />
      <Footer />
    </>
  );
};

export default Home;
