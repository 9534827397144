import React from "react";
import { PlainText, StyledCard } from "../../globalStyles";
import { Space, Avatar, Row } from "antd";
import { ProjectCardButton } from "./ProjectCard.elements";

const ProjectCard = ({ title, description, img, hasButton, buttonLabel }) => {
  return (
    <StyledCard>
      <Space direction="vertical" size={120}>
        <Row>
          <Space direction="vertical" size={20}>
            <Avatar
              size={50}
              style={{ backgroundColor: "#fff" }}
              src={<img src={img} alt="avatar" />}
            />
            <PlainText large>{title}</PlainText>
            <PlainText style={{ textAlign: "justify" }}>
              {description}
            </PlainText>
          </Space>
        </Row>
        {/* {hasButton ? <ProjectCardButton>{buttonLabel}</ProjectCardButton> : ""} */}
      </Space>
    </StyledCard>
  );
};

export default ProjectCard;
