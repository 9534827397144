import React from "react";
import { Col, Row, Space } from "antd";
import { PlainText, SmallHeaderDark } from "../../globalStyles";

import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";

export const LocationBlock = ({ location, address, number }) => {
  return (
    <Row>
      <Col>
        <Space size={5} direction="vertical">
          <SmallHeaderDark>{location}</SmallHeaderDark>
          <Space size={5} direction="vertical">
            <Row>
              <Space size={10} direction="horizontal">
                <AiOutlineMail />
                <PlainText dark style={{ marginTop: "18px" }}>
                  {address}
                </PlainText>
              </Space>
            </Row>
            <Row>
              <Space size={10} direction="horizontal">
                <BiPhone />
                <PlainText dark style={{ marginTop: "18px" }}>
                  {number}
                </PlainText>
              </Space>
            </Row>
            <Row>
              <Space size={10} direction="horizontal">
                <GrLocation />
                <PlainText dark style={{ marginTop: "18px" }}>
                  info@biosec.com.ng
                </PlainText>
              </Space>
            </Row>
          </Space>
        </Space>
      </Col>
    </Row>
  );
};
