import React from "react";
import { DataCopy1Icon, LightButton } from "../../globalStyles";
import { GiRock } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from "../Pricing/Pricing.elements";
import { ArrowRightOutlined } from "@ant-design/icons";

const ServiceCard = (props) => {
  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <PricingWrapper>
        <PricingContainer>
          <PricingCard to="/services">
            <PricingCardInfo>
              <PricingCardIcon>
                <DataCopy1Icon alt="" src={props.imgSrc} />
              </PricingCardIcon>
              <PricingCardCost>{props.serviceName}</PricingCardCost>
              <PricingCardFeatures>
                <PricingCardFeature>
                  More details <ArrowRightOutlined />
                </PricingCardFeature>
              </PricingCardFeatures>
            </PricingCardInfo>
          </PricingCard>
        </PricingContainer>
      </PricingWrapper>
    </IconContext.Provider>
  );
};

export default ServiceCard;
