import styled from "styled-components";

export const OurTeamCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 400px;
  width: 300px;
  max-width: 100%;
  margin-right: auto;
  background-color: transparent;
  margin-left: auto;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const OurTeamCardBg = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50%;
  background-image: url("/our_team_bg.png");
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Img = styled.img`
  position: absolute;
  width: 90%;
  height: auto;
  padding-right: 0;
  border: 0;
  max-width: 100%;
`;

export const LinkedinLogo = styled.img`
  position: absolute;
  bottom: 30%;
  left: 43%;
  padding-right: 0;
  border: 0;
  max-width: 100%;
  scale: 0.5;

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
    cursor: pointer;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 400;
  color: #292f6f;
`;
