import React from "react";
import { ServicesBg } from "./ServicesSection.elements";

import { servicesList } from "../../pages/Services/Data";
import ServicesCardsSection from "./ServicesCardsSection";

const ServicesBgSection = () => {
  return (
    <>
      <ServicesBg>
        <ServicesCardsSection {...servicesList} />
      </ServicesBg>
    </>
  );
};

export default ServicesBgSection;
