import React from "react";
import { Container } from "../../globalStyles";
import { Col, Row, Divider } from "antd";
import { TopLine, Heading } from "../InfoSection/InfoSection.elements";
import { SubtitleFull } from "./OurProjectsSection.elements";

const OurProjectsDescription = ({ headline, description, topLine }) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <TopLine>
              <Row style={{ paddingTop: "50px" }}>
                <Col
                  xs={11}
                  sm={11}
                  md={3}
                  lg={3}
                  style={{ marginTop: "10px" }}
                >
                  {topLine}
                </Col>
                <Col xs={4} sm={4} md={1} lg={1}>
                  <Divider
                    orientation="left"
                    style={{ borderTop: "3px solid red" }}
                  />
                </Col>
              </Row>
            </TopLine>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Heading>{headline}</Heading>
            <SubtitleFull>{description}</SubtitleFull>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OurProjectsDescription;
