import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import {
  HamburgerIcon,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavbarContainer,
  DropdownContainer, // New styled component for the dropdown
  DropdownItem,
} from "./Navbar.elements";
import "../../index.css";
import { Link, withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";

const activeTab = (history, path) => {
  if (history.location.pathname === path) {
    return { color: "Red", fontWeight: "400" };
  }
};

function MyNavbar({ history }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [homeClick, setHomeClick] = useState(false);
  const [servicesClick, setServicesClick] = useState(false);
  const [productsClick, setProductsClick] = useState(false);
  const [careersClick, setCareersClick] = useState(false);
  const [contactsClick, setContactsClick] = useState(false);
  const [projectsClick, setProjectsClick] = useState(false);

  const handleHomeClick = () => {
    setHomeClick(true);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleServicesClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(true);
  };
  const handleProductsClick = () => {
    setHomeClick(false);
    setProductsClick(true);
    setServicesClick(false);
  };
  const handleCareersClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleContactsClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };

  const handleProjectsClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  });

  window.addEventListener("resize", showButton);
  const [servicesDropdown, setServicesDropdown] = useState(false);

  const handleToggleServicesDropdown = () => {
    setServicesDropdown(!servicesDropdown);
  };

  return (
    // <Nav>
    //   <NavbarContainer>
    //     <NavLogo
    //       to="/"
    //       onClick={() => {
    //         closeMobileMenu();
    //         setHomeClick(false);
    //         setServicesClick(false);
    //         setProductsClick(false);
    //         setCareersClick(false);
    //         setContactsClick(false);
    //         setProjectsClick(false);
    //         setServicesDropdown(false);
    //       }}
    //     >
    //       <Logo />
    //     </NavLogo>
    //     <HamburgerIcon onClick={handleClick} style={{ color: "white" }}>
    //       {click ? <FaTimes /> : <FaBars />}
    //     </HamburgerIcon>
    //     <NavMenu onClick={handleClick} click={click}>
    //       <NavItem onClick={handleHomeClick} homeClick={homeClick}>
    //         <NavLinks
    //           style={activeTab(history, "/about")}
    //           to="/about"
    //           onClick={() => {
    //             closeMobileMenu();
    //             setHomeClick(true);
    //             setServicesClick(false);
    //             setProductsClick(false);
    //             setCareersClick(false);
    //             setContactsClick(false);
    //             setProjectsClick(false);
    //             setServicesDropdown(false);
    //           }}
    //           className="myNav"
    //         >
    //           {" "}
    //           ABOUT US
    //         </NavLinks>
    //       </NavItem>

    //       <NavItem>
    //         <NavLinks
    //           to="#"
    //           style={activeTab(history, "/solutions")}
    //           onClick={() => {
    //             closeMobileMenu();
    //             handleServicesClick();
    //             handleToggleServicesDropdown(); // Toggle the dropdown on Services click
    //           }}
    //           className="myNav"
    //         >
    //           SERVICES <IoIosArrowDown />
    //         </NavLinks>
    //         {/* Conditional rendering of the Services dropdown */}
    //         {servicesDropdown && (
    //           <DropdownContainer>
    //             <DropdownItem
    //               style={activeTab(history, "/solutions")}
    //               to="/solutions"
    //               onClick={() => {
    //                 setServicesDropdown(false);
    //                 closeMobileMenu();
    //                 handleServicesClick();
    //               }}
    //             >
    //               OUR SOLUTIONS
    //             </DropdownItem>
    //             <DropdownItem
    //               style={activeTab(history, "/services")}
    //               to="/services"
    //               onClick={() => {
    //                 setServicesDropdown(false);
    //                 closeMobileMenu();
    //                 handleServicesClick();
    //               }}
    //             >
    //               OUR SERVICES
    //             </DropdownItem>
    //             {/* Add more services as needed */}
    //           </DropdownContainer>
    //         )}
    //       </NavItem>
    //       <NavItem onClick={handleProjectsClick} servicesClick={projectsClick}>
    //         <NavLinks
    //           style={activeTab(history, "/projects")}
    //           to="/projects"
    //           onClick={() => {
    //             closeMobileMenu();
    //             setHomeClick(false);
    //             setServicesClick(false);
    //             setCareersClick(false);
    //             setProductsClick(false);
    //             setContactsClick(false);
    //             setProjectsClick(true);
    //             setServicesDropdown(false);
    //           }}
    //           className="myNav"
    //         >
    //           PROJECTS
    //         </NavLinks>
    //       </NavItem>

    //       <NavItem onClick={handleCareersClick} servicesClick={careersClick}>
    //         <NavLinks
    //           style={activeTab(history, "/careers")}
    //           to="/careers"
    //           onClick={() => {
    //             closeMobileMenu();
    //             setHomeClick(false);
    //             setServicesClick(false);
    //             setProductsClick(false);
    //             setCareersClick(true);
    //             setContactsClick(false);
    //             setProjectsClick(false);
    //             setServicesDropdown(false);
    //           }}
    //           className="myNav"
    //         >
    //           CAREERS
    //         </NavLinks>
    //       </NavItem>

    //       <NavItem onClick={handleContactsClick} servicesClick={contactsClick}>
    //         <NavLinks
    //           style={activeTab(history, "/contact")}
    //           to="/contact"
    //           onClick={() => {
    //             closeMobileMenu();
    //             setHomeClick(false);
    //             setServicesClick(false);
    //             setProductsClick(true);
    //             setCareersClick(false);
    //             setContactsClick(true);
    //             setProjectsClick(false);
    //             setServicesDropdown(false);
    //           }}
    //           className="myNav"
    //         >
    //           CONTACT
    //         </NavLinks>
    //       </NavItem>
    //     </NavMenu>
    //   </NavbarContainer>
    // </Nav>

    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
      variant="dark"
      data-bs-theme="dark"
    >
      <Container>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="/about" style={{ paddingRight: "20px" }}>
              WHO WE ARE
            </Nav.Link>

            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#8F949A",
                  fontSize: "14px",
                  padding: "6px 20px 0px 0px",
                }}
              >
                WHAT WE DO
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ zIndex: "1000" }}>
                <Dropdown.Item
                  href="/remote-sensing"
                  style={{
                    color: "#8F949A",
                    fontSize: "14px",
                  }}
                >
                  SPACE
                </Dropdown.Item>
                <Dropdown drop="start">
                  <Dropdown.Toggle
                    variant="success"
                    id="nested-dropdown"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#8F949A",
                      fontSize: "14px",
                    }}
                  >
                    IDENTITY
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      zIndex: "1000",
                      color: "#8F949A",
                      fontSize: "14px",
                    }}
                  >
                    <Dropdown.Item
                      href="/identity-management"
                      style={{
                        zIndex: "1000",
                        color: "#8F949A",
                        fontSize: "14px",
                      }}
                    >
                      IDENTITY
                    </Dropdown.Item>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="nested-dropdown"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "#8F949A",
                          fontSize: "14px",
                        }}
                      >
                        SERVICES
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          zIndex: "1000",
                          color: "#8F949A",
                          fontSize: "14px",
                        }}
                      >
                        <Dropdown.Item
                          href="/solutions"
                          style={{
                            zIndex: "1000",
                            color: "#8F949A",
                            fontSize: "14px",
                          }}
                        >
                          OUR SOLUTIONS
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/services"
                          style={{
                            zIndex: "1000",
                            color: "#8F949A",
                            fontSize: "14px",
                          }}
                        >
                          OUR SERVICES
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown.Item
                      href="/projects"
                      style={{ color: "#8F949A", fontSize: "14px" }}
                    >
                      PROJECTS
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/contact"
                      style={{ color: "#8F949A", fontSize: "14px" }}
                    >
                      CONTACTS
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link eventKey={2} href="/careers">
              CAREERS
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withRouter(MyNavbar);
