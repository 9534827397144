import React from "react";
import { Container } from "../../globalStyles";
import { Space } from "antd";
import {
  InfoRow,
  Heading,
  SubtitleFull,
  Img,
  ImgColumn,
  TextWrapper,
  InfoColumn,
} from "../InfoSection/InfoSection.elements";
import biosuite from "../../images/biosuite-img.png";
import biosuite2 from "../../images/BioSuite™.svg";

const BiosuiteSection = () => {
  return (
    <>
      <Container>
        <InfoRow imgStart={true} style={{ marginTop: "150px" }}>
          <InfoColumn>
            <TextWrapper>
              <Space direction="vertical" size={20}>
                <Heading>{<Img src={biosuite2} />}</Heading>
                <SubtitleFull style={{ textAlign: "justify" }}>
                  <Space direction="vertical" size={10}>
                    <SubtitleFull>
                      BioSuite™ is a comprehensive collection of biometric and
                      demographic solutions, offering unparalleled customization
                      and multiplatform compatibility. The BioSuite™ portfolio
                      includes the following state-of-the-art solutions:
                    </SubtitleFull>
                    <SubtitleFull>
                      <li>
                        BioEnroll™: A demographic and biometric data capturing
                        system, available on PC, Android, and API platforms.
                      </li>
                    </SubtitleFull>
                    <li>
                      Biosecure™: A biometric verification and matching solution
                      that utilizes biometric data for 1:1 (one-to-one
                      verification) or 1:N (one-to-many identification)
                      matching. This unique biometric software is available on
                      desktop, Android, and API platforms.
                    </li>
                    <SubtitleFull>
                      <li>
                        BioConnect™: The backbone of the BioSuite™ ecosystem,
                        this server-side application efficiently handles all
                        data processing and management. With BioSuite™,
                        organisations can benefit from highly customisable,
                        reliable, and efficient biometric and authentication
                        solutions that meet their unique requirements.
                      </li>
                    </SubtitleFull>
                  </Space>
                </SubtitleFull>
              </Space>
            </TextWrapper>
          </InfoColumn>
          <ImgColumn>
            <Img src={biosuite} alt="biosuite" />
          </ImgColumn>
        </InfoRow>
      </Container>
    </>
  );
};

export default BiosuiteSection;
