import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 50px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 50px 0;
  }
`;

export const InfoSecTwo = styled.div`
  color: #fff;
  padding-top: 50px;
  padding-bottom: 70px;
  background-image: url("/fingerprint-2@2x.png");
  background-image: url("/fingerprint-2@2x.png");
  background-repeat: no-repeat;

  background-size: 500px;
  background-position: top-left;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 50px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 50px 0;
  }
`;
export const InfoSecTwoCopy = styled.div`
  color: #fff;
  padding-top: 50px;
  padding-bottom: 70px;
  background-image: url("/fingerprint.png");
  background-repeat: no-repeat;

  background-size: 400px;
  background-position: right;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 50px 0;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 50px 0;
  }
`;

export const InfoSecThree = styled.div`
  background-image: url("/services_bg.png");
  background-image: url("/services_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  padding: 50px 0;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding: 50px 0;
    background-color: #282930;
    background-image: none;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    background-color: #282930;
    padding: 50px 0;
    background-image: none;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 80%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const InfoColumn2 = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 40%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoColumnCareer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoColumnThree = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 40%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImgColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImgColumn2 = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 30%;
  flex-basis: 30%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImgColumnThree = styled.div`
  width: 100%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 780px;
  padding-top: 0;
  padding-bottom: 60px;
  padding-right: 20px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 35px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 35px;
  }
`;

export const TextWrapperThree = styled.div`
  max-width: 780px;
  padding-top: 0;
  padding-right: 20px;
  text-align: right !important;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 35px;
    text-align: left !important;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 35px;
    text-align: left !important;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 405px;
  display: flex;
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
  justify-content: ${({ start }) => (start ? "flex-start" : "flex-end")};
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#FFFFFF" : "#282930")};
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
`;

export const TopLine2 = styled.div`
  color: var(--black, #282930);
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 530px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.1;
  font-weight: 400;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};

  @media (min-width: 480px) and (max-width: 1200px) {
    font-size: 20px;
    text-align: left;
    font-weight: 600;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
  }
`;

export const SubtitleFull = styled.p`
  max-width: 640px;
  margin-bottom: 35px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;
export const SubtitleFull2 = styled.p`
  max-width: 640px;
  margin-bottom: 35px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#f7f8fa" : "#1c2237")};
`;

export const SubtitleShort = styled.p`
  max-width: 480px;
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#a9b3c1" : "#1c2237")};
`;

export const AreaTooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #000000;
  padding: 8px;
  border-radius: 4px;
  font-size: 24px;
  display: none;
`;

export const Area = styled.area`
  /* Add your area styles here */
`;
