export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "OUR PROJECTS",
  headline: "SOME BENEFITS OF JOINING BIOSEC",
  description:
    "Each project is carefully crafted to address specific business challenges and provide measurable results.",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const careerTwo = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "OUR PROJECTS",
  headline: "JOIN OUR TEAM TO INVENT THE FUTURE",
  description:
    "Each project is carefully crafted to address specific business challenges and provide measurable results.",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const careerThree = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "CORE STRENGTHS",
  headline: "JOIN OUR TEAM TO INVENT THE FUTURE",
  description:
    "Each project is carefully crafted to address specific business challenges and provide measurable results.",
  buttonLabel: "",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const careerHeader = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "CORE STRENGHTS",
  headline: "LIFE AT BIOSEC SOLUTIONS",
  description:
    "At Biosec Solutions, we embrace diversity and aim to recruit from various walks of life. Guided by integrity, creativity, merit, and growth, we foster a culture of innovation, teamwork, and progression to help us reach our potential.",
  buttonLabel: "",
  imgStart: "start",
  img: require("../../assets/img/career.jpeg"),
  alt: "Credit Card",
};

export const projectsList = [homeObjOne, careerTwo, careerThree, careerHeader];
