import React from "react";
import {
  InfoSecTwo,
  InfoRow,
  InfoColumn,
  ImgColumn,
  ImgColumn2,
  TextWrapper,
  TopLine,
  Heading,
  SubtitleFull,
  Img,
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";
import { Col, Divider, Row } from "antd";
import { HeadingMedium } from "../CustomBg/CustomBg.elements";
import { Screenshot20230621151140RIconStory } from "../CustomBg/CustomBg.elements";

import CustomBullet from "../Bullet/bullet";

const InfoSectionStory = ({
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
}) => {
  return (
    <>
      <Container>
        <InfoRow imgStart={imgStart}>
          <InfoColumn>
            <TextWrapper>
              <TopLine>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    style={{ marginTop: "12px" }}
                  >
                    {topLine}
                  </Col>
                  <Col xs={5} sm={5} md={2} lg={2}>
                    <Divider
                      orientation="left"
                      style={{ borderTop: "3px solid red" }}
                    />
                  </Col>
                </Row>
              </TopLine>

              <Heading>{headline}</Heading>
              <SubtitleFull>{description}</SubtitleFull>

              <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={15} lg={15}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Screenshot20230621151140RIconStory
                      alt=""
                      src="/vision.png"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <SubtitleFull>
                      Identity-enabled solutions for Africa
                    </SubtitleFull>
                  </Col>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Screenshot20230621151140RIconStory
                      alt=""
                      src="/mission.png"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <SubtitleFull>Inventing the future</SubtitleFull>
                  </Col>
                </Col>
              </Row>
            </TextWrapper>
          </InfoColumn>
          <ImgColumn2>
            <Img src={img} alt={alt} />
          </ImgColumn2>
        </InfoRow>
      </Container>
    </>
  );
};

export default InfoSectionStory;
