import React, { useState } from "react";
import { Col, Row, Space } from "antd";
import {
  Container,
  StyledInput,
  StyledLabel,
  StyledForm,
  StyledTextArea,
  LightButtonOutline,
  DarkButton,
} from "../../globalStyles";
import { LocationBlock } from "../../components/LocationSection/LocationSection";
import {
  PlainText,
  SmallHeaderDark,
  SmallHeaderDark1,
  SmallHeaderDark2,
  MediumHeaderDark,
} from "../../globalStyles";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Footer } from "../../components";

const Contact = () => {
  const [formData, setFormData] = useState({
    user: "",
    to: "info@biosec.com.ng",
    salutation: "",
    body: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Send POST request
    axios
      .post("http://41.184.212.26:8081/mails/send", formData)
      .then((response) => {
        console.log("Response:", response.data);
        window.alert("Message sent successfully!");
        // Handle successful response here
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      })
      .finally(() => {
        setIsLoading(false); // Stop loading, whether success or error
      });
  };
  return (
    <>
      <Container>
        <div>
          <Helmet>
            <title>Biosec Solutions | biosec.com.ng</title>
            {/* <meta
            name="description"
            content="This is a description of my page content with relevant keywords."
          /> */}
            {/* Add other meta tags as needed */}
          </Helmet>
          {/* Your page content */}
        </div>
        <Row>
          <Col span={24} xs={24} lg={24} md={24}>
            <Row style={{ padding: "50px" }}>
              <Col span={12} xs={24} lg={12}>
                <MediumHeaderDark>STAY CONNECTED</MediumHeaderDark>
                <Space direction="vertical" size={30}>
                  <SmallHeaderDark1>CONTACT US</SmallHeaderDark1>
                  <LocationBlock
                    location="ABUJA OFFICE:"
                    address="8b Rumbek close, Zone 6, Wuse, Abuja"
                    number="+234 815 534 3718"
                  />
                  <LocationBlock
                    location="LAGOS OFFICE:"
                    address="6th Floor Wesley House, 21/22 Marina Rd, Lagos"
                    number="+234 818 437 2194"
                  />
                </Space>
                <SmallHeaderDark1 style={{ paddingTop: "10px" }}>
                  OPENING HOURS
                </SmallHeaderDark1>
                <PlainText dark>Monday - Friday</PlainText>
                <PlainText dark>08:00AM - 5:00PM</PlainText>
              </Col>
              <Col span={12} xs={24} lg={12}>
                <Space size={15} direction="vertical">
                  <SmallHeaderDark2>GET IN TOUCH WITH US </SmallHeaderDark2>
                  <PlainText dark>
                    Need any assistance? Our support team is always on standby
                    to provide you with all the guidance and information you
                    need.
                  </PlainText>
                  <PlainText dark>Contact us via:</PlainText>
                  <StyledForm onSubmit={handleSubmit}>
                    <StyledLabel
                      htmlFor="fullName"
                      style={{ fontWeight: "600px" }}
                    >
                      Your name
                    </StyledLabel>
                    <StyledInput
                      type="text"
                      id="fullName"
                      name="fullName"
                      placeholder="Full name*"
                      value={formData.fullName}
                      onChange={handleInputChange}
                      required
                    />

                    <StyledLabel htmlFor="phoneNumber">
                      Your phone number
                    </StyledLabel>
                    <StyledInput
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Phone number*"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />

                    <StyledLabel htmlFor="email">
                      Your email address
                    </StyledLabel>
                    <StyledInput
                      type="email"
                      id="email"
                      name="user"
                      placeholder="Email address*"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />

                    <StyledLabel htmlFor="message">Your message</StyledLabel>
                    <StyledTextArea
                      id="message"
                      name="body"
                      placeholder="Message*"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    />

                    <DarkButton
                      type="submit"
                      disabled={isLoading}
                      style={{ color: "white" }}
                    >
                      {isLoading ? "Sending..." : "SEND"}
                    </DarkButton>
                  </StyledForm>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24} xs={24} lg={24} md={24}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.9422398554743!2d7.452766975135029!3d9.069026890993934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0bbaf640916b%3A0xd83cd2f4a3fd0bc0!2sBiosec%20Solutions!5e0!3m2!1sen!2sng!4v1690891618901!5m2!1sen!2sng"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
