import styled from "styled-components";

export const ProjectCardButton = styled.button`
  border-radius: 4px;
  background: #282930;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  border: solid 1px #fff;
  cursor: pointer;
  width: 100%;
  &:hover {
    transition: all 0.3s ease-out;
    color: #000;
    background-color: #fff;
  }
`;
