import React from "react";
import { Container } from "../../globalStyles";
import { Col, Row, Divider } from "antd";
import { TopLine } from "../CustomBg/CustomBg.elements";
import { Heading } from "../InfoSection/InfoSection.elements";

const OurSolutionsSection = () => {
  return (
    <>
      <Container style={{ paddingTop: "50px" }}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <TopLine>
              <Row>
                <Col
                  xs={11}
                  sm={11}
                  md={3}
                  lg={3}
                  style={{ marginTop: "16px" }}
                >
                  OUR SOLUTIONS
                </Col>
                <Col xs={4} sm={4} md={1} lg={1}>
                  <Divider
                    orientation="left"
                    style={{ borderTop: "3px solid red" }}
                  />
                </Col>
              </Row>
            </TopLine>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Heading>
              EXPLORE THE POWER OF NEXT-LEVEL BIOMETRICS TECHNOLOGY WITH OUR
              ZERO TRUST SECURITY SOLUTIONS.
            </Heading>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OurSolutionsSection;
