import React from "react";
import { TopLine, Heading } from "../InfoSection/InfoSection.elements";
import { Col, Divider, Row } from "antd";
import { HeadingMedium } from "../CustomBg/CustomBg.elements";
import { Benefits } from "./Benefits.elements";
import { Screenshot20230621151140RIcon } from "../CustomBg/CustomBg.elements";

const BenefitsSection = ({ headline }) => {
  return (
    <center>
      <Benefits>
        <center>
          <h3
            style={{
              marginBottom: "60px",
            }}
          >
            {headline}
          </h3>
        </center>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={4} lg={4}>
                <Screenshot20230621151140RIcon
                  alt=""
                  src="/career_growth.png"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ marginTop: "10px" }}
              >
                <HeadingMedium>Career Growth</HeadingMedium>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={4} lg={4}>
                <Screenshot20230621151140RIcon alt="" src="/capacitys.svg" />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ marginTop: "10px" }}
              >
                <HeadingMedium>Capacity-Building Culture</HeadingMedium>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={4} lg={4}>
                <Screenshot20230621151140RIcon
                  alt=""
                  src="/globe_removebg.svg"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ marginTop: "10px" }}
              >
                <HeadingMedium>Global Outlook</HeadingMedium>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={24} md={4} lg={4}>
                <Screenshot20230621151140RIcon
                  alt=""
                  src="/welfare_staff.svg"
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{ marginTop: "10px" }}
              >
                <HeadingMedium>Staff Welfare</HeadingMedium>
              </Col>
            </Row>
          </Col>
        </Row>
      </Benefits>
    </center>
  );
};
export default BenefitsSection;
