import React from "react";
import { Img, ImgContainer } from "./OurProjectsSection.elements";
import { Container } from "../../globalStyles";
import { Col, Row } from "antd";

import fingerprintImg from "../../images/fingerprintImg.png";
import { HeaderBackground } from "./OurProjectsSection.elements";
import { Heading } from "../HeroSection/HeroSection.elements";

const ImageSection = () => {
  return (
    <>
      <ImgContainer>
        <Row style={{ paddingTop: "50px" }} align={"bottom"}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Img src={fingerprintImg} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <HeaderBackground>
              <Heading lightText={true}>
                LET’S BUILD A SECURE FUTURE WITH INNOVATIVE SOLUTIONS.
              </Heading>
            </HeaderBackground>
          </Col>
        </Row>
      </ImgContainer>
    </>
  );
};

export default ImageSection;
