import React from "react";
import { Container } from "../../globalStyles";
import { Col, Row, Divider } from "antd";
import DetailedServiceCard from "../DetailedServiceCard/DetailedServiceCard";
import { TopLine } from "../CustomBg/CustomBg.elements";
import { CustomBgSecStory, Img } from "../Timelines/Timelines.elements";
import { InfoSectionStory, HeroSection, InfoSectionThree } from '../../components';
import { homeObjOne, homeObjTwo} from '../../pages/AboutPage/Data';
import { Card } from "antd";

const StorySection = ({ img }) => {
  return (
    <>
      <CustomBgSecStory>
        <Container style={{ paddingTop: "50px", paddingBottom: "90px" }}>
          <Card
            style={{
              width: "100%",
              height: "fit",
            }}
          >
           <InfoSectionStory {...homeObjTwo} />
          </Card>
        </Container>
      </CustomBgSecStory>
    </>
  );
};

export default StorySection;
