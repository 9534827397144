import React from "react";
import Icon from "@ant-design/icons";

const bulletsvg = () => (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" stroke="#292F6F" stroke-width="2" />
      <g clip-path="url(#clip0_35_704)">
        <path
          d="M10.4996 14.3791L17.3936 7.48438L18.4548 8.54488L10.4996 16.5001L5.72656 11.7271L6.78706 10.6666L10.4996 14.3791Z"
          fill="#EC1D30"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_704">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );

  const CustomBulletIcon = (props) => <Icon component={bulletsvg} {...props} />;

  function CustomBullet() {
    
    return (
        <CustomBulletIcon
                        style={{
                          color: "hotpink",
                        }}
                      />
    );
  }

  export default CustomBullet;