import React from "react";
import { Container } from "../../globalStyles";
import { Col, Row, Divider } from "antd";
import DetailedServiceCard from "../DetailedServiceCard/DetailedServiceCard";
import { TopLine } from "../CustomBg/CustomBg.elements";

const ServicesCardsSection = (props) => {
  return (
    <>
      <Container style={{ paddingTop: "50px", paddingBottom: "90px" }}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <TopLine style={{ textAlign: "center" }}>
              <Row justify="center">
                <Col xs={4} sm={4} md={1} lg={1}>
                  <Divider
                    orientation="right"
                    style={{ borderTop: "3px solid red" }}
                  />
                </Col>
                <Col
                  xs={11}
                  sm={11}
                  md={3}
                  lg={3}
                  style={{ marginTop: "16px" }}
                >
                  OUR SERVICES
                </Col>
                <Col xs={4} sm={4} md={1} lg={1}>
                  <Divider
                    orientation="left"
                    style={{ borderTop: "3px solid red" }}
                  />
                </Col>
              </Row>
            </TopLine>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={20} style={{ paddingTop: "10px" }}>
              {Object.values(props).map((project) => (
                <Col xs={24} sm={24} md={24} lg={12}>
                  <DetailedServiceCard
                    title={project.title}
                    description={project.description}
                    img={project.img}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ServicesCardsSection;
