import styled from "styled-components";

export const CustomBgSec = styled.div`
  background-image: url("/print_bg2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #282930;
  background-position: right;
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#282930" : "#282930")};
  line-height: 16px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const TextWrapper = styled.div`
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;
  }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Screenshot20230621151140RIcon = styled.img`
  position: relative;
  width: 50px;
  height: 51px;
  object-fit: cover;

  @media (min-width: 480px) and (max-width: 1200px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Screenshot20230621151140RIconStory = styled.img`
  position: relative;
  width: 100px;
  height: 31px;

  @media (min-width: 480px) and (max-width: 1200px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HeadingMedium = styled.h3`
  margin-bottom: 24px;
  line-height: 1.1;
  font-weight: 200;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};

  @media (min-width: 480px) and (max-width: 1200px) {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
  }
`;

export const Cpu1Parent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
`;
