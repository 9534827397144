const serviceOne = {
  title: "DE-DUPLICATION AS A SERVICE (DAAS) ",
  description:
    "Biosec's cutting-edge data deduplication service improves data quality by eliminating redundant or duplicate data from your datasets. Our solution ensures optimal storage utilization and significant cost savings without complex infrastructure or time-consuming manual processes. Our advanced deduplication techniques ensure that only one unique instance of data is retained. ",
  img: require("../../images/serviceCard1.png"),
  alt: "Vault",
};

const serviceTwo = {
  title: "SOFTWARE AS A SERVICE (SAAS)",
  description:
    "Biosec offers affordable, cloud-based productivity software with no need for expensive servers. Pay monthly or yearly for access. Features 99.999% uptime and strong data protection. Enjoy seamless cloud storage with our on-demand software. ",
  img: require("../../images/serviceCard2.png"),
  alt: "Vault",
};

const serviceThree = {
  title: "ENROLMENT AS A SERVICE (EAAS)",
  description:
    "Entrust Biosec Solutions with your enumeration needs and enjoy the benefits of expert services without the need for capital investments. Our extensive state-of-the-art enrolment software, vast experience, and unparalleled expertise make us the ideal choice for enrolling your target demographic. Contact us to learn more. ",
  img: require("../../images/serviceCard3.png"),
  alt: "Vault",
};

const serviceFour = {
  title: "BIOMETRICS TRAINING",
  description:
    "As a member of the Biometrics Institute, a leader in ethical biometrics use and industry best practices, we offer top-notch biometrics training in Nigeria. Upgrading to advanced packages will enhance your cybersecurity and biometrics expertise and keep you ahead in the industry. ",
  img: require("../../images/serviceCard4.png"),
  alt: "Vault",
};

export const servicesList = [serviceThree, serviceOne, serviceTwo, serviceFour];
