import React from "react";
import {
  InfoSecTwo,
  InfoRow,
  InfoColumnCareer,
  ImgColumn,
  TextWrapper,
  TopLine,
  Heading,
  SubtitleFull,
  Img,
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";
import { Col, Divider, Row } from "antd";

import CustomBullet from "../Bullet/bullet";
import SimpleSlider from "../SimpleSlider/SimpleSlider";
import SliderData from "../SimpleSlider/SliderData";
import fingerprintImg from "../../images/career.jpeg";
import fingerprintImg2 from "../../images/bulus.jpeg";
import fingerprintImg3 from "../../images/sarah.jpeg";
import {
  Img2,
  ImgContainer,
} from "../OurProjectsSection/OurProjectsSection.elements";
import { Carousel } from "antd";
import SimpleImageSlider from "react-simple-image-slider";

const InfoSectionCareer = ({
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
}) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const images = [
    { url: "career.jpeg" },
    { url: "bulus.jpeg" },
    { url: "sarah.jpeg" },
  ];
  return (
    <>
      <Container style={{ marginTop: "60px" }}>
        <InfoRow imgStart={imgStart}>
          <Row>
            <Col xs={0} sm={0} md={12} lg={12}>
              {/* <Img2 src={fingerprintImg} /> */}
              <SimpleImageSlider
                width={600}
                height={400}
                images={images}
                showBullets={true}
                showNavs={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <InfoColumnCareer>
                <TextWrapper>
                  <Heading>{headline}</Heading>
                  <SubtitleFull>{description}</SubtitleFull>
                </TextWrapper>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Img2 src={fingerprintImg} />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Img2 src={fingerprintImg2} />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Img2 src={fingerprintImg3} />
                  </Col>
                </Row>
              </InfoColumnCareer>
            </Col>
          </Row>
        </InfoRow>
      </Container>
    </>
  );
};

export default InfoSectionCareer;
