import styled from "styled-components";
import Card from "antd/es/card/Card";

export const StyledServiceCard = styled(Card)`
  background-color: #fff;
  color: #fafaff;
  font-size: 16px;
  height: auto;
  margin: 30px;
  max-width: 500px;
  box-shadow: 0 10px 20px rgba(200, 200, 255, 0.2);

  @media screen and (max-width: 960px) {
    margin: 20px;
  }
`;
