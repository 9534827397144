import React from "react";
import {
  InfoSecTwo,
  InfoSecTwoCopy,
  InfoRow,
  InfoColumn,
  ImgColumn,
  TextWrapper,
  TopLine,
  TopLine2,
  Heading,
  SubtitleFull,
  Img,
} from "./InfoSection.elements";
import { Container } from "../../globalStyles";
import { Divider } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CustomBullet from "../Bullet/bullet";

const InfoSection = ({
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  description2,
  img,
  alt,
  imgStart,
}) => {
  return (
    <>
      <InfoSecTwo>
        <InfoSecTwoCopy>
          <Container>
            <InfoRow imgStart={imgStart}>
              <InfoColumn>
                <TextWrapper>
                  <TopLine lightTopLine={lightTopLine}>
                    <Row>
                      <Col sm={9} md={3} lg={3} style={{ marginTop: "10px" }}>
                        {topLine}
                      </Col>
                      <Col xs={2} sm={2} md={2} lg={2}>
                        <Divider
                          orientation="left"
                          style={{ borderTop: "3px solid red" }}
                        />
                      </Col>
                    </Row>
                  </TopLine>

                  <Heading lightText={lightText}>{headline}</Heading>

                  <SubtitleFull lightTextDesc={lightTextDesc}>
                    {description}
                  </SubtitleFull>

                  {description2 ? (
                    <SubtitleFull lightTextDesc={lightTextDesc}>
                      {description2}
                    </SubtitleFull>
                  ) : (
                    ""
                  )}

                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <TopLine2 lightTopLine={lightTopLine}>
                        Here is what we do at Biosec Solutions:
                      </TopLine2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <TopLine2 lightTopLine={lightTopLine}>
                            <CustomBullet /> IDENTITY VERIFICATION
                          </TopLine2>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <TopLine2 lightTopLine={lightTopLine}>
                            <CustomBullet /> IAM: IDENTITY AND ACCESS MANAGEMENT
                          </TopLine2>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <TopLine2 lightTopLine={lightTopLine}>
                            <CustomBullet /> NIMC NIN VERIFICATION SERVICES
                            (NVS)
                          </TopLine2>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                          <TopLine2>
                            <CustomBullet /> BIOMETRIC AUTHENTICATION
                          </TopLine2>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <TopLine2>
                            <CustomBullet /> NIMC DIASPORA ENROLMENT
                          </TopLine2>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TextWrapper>
              </InfoColumn>
              <ImgColumn>
                <Img src={img} alt={alt} />
              </ImgColumn>
            </InfoRow>
          </Container>
        </InfoSecTwoCopy>
      </InfoSecTwo>
    </>
  );
};

export default InfoSection;
