import React from "react";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Details from "./components/Details";
import GetStarted from "./components/GetStarted";
import GetStarted2 from "./components/GetStarted2";
import GridComponent from "./components/GridComponent";
import { Footer } from "../../components";
import { DataCopy1Icon2, LightButton } from "../../globalStyles";

// Import images for the news cards
import newsImage1 from "../../assets/img/screen.png";
import newsImage2 from "../../assets/img/screen2.png";
import newsImage3 from "../../assets/img/screen3.png";
import newsImage4 from "../../assets/img/screen4.png";

import imgDetail2 from "../../assets/img/Images-10.jpg";
import imgDetail3 from "../../assets/img/Images-05.jpg";
import imgDetail4 from "../../assets/img/Images-07.jpg";

import logo1 from "../../assets/img/clients/04.png";
import logo2 from "../../assets/img/clients/Rovio-Logo.jpg";
import logo3 from "../../assets/img/clients/ecf_logo_2014_blue_on_white.jpg";
import logo4 from "../../assets/img/clients/nordeus_logo.png";
import logo5 from "../../assets/img/clients/04.png";
import logo6 from "../../assets/img/clients/06.png";
import ScrollAnimation from "react-animate-on-scroll";

const LatestNewsCard = ({ imageUrl, title, subtext }) => {
  return (
    <div className="col-12 col-lg-3 col-md-6 col-sm-12 mb-4">
      <div
        className="card"
        style={{ backgroundColor: "#212121", width: "auto" }}
      >
        <img
          src={imageUrl}
          className="card-img-top"
          alt={title}
          style={{ height: "200px", objectFit: "cover" }}
        />
        <div className="card-body" style={{ color: "#FFFFFF" }}>
          <h6 className="card-title">{title}</h6>
          <p className="card-text">{subtext}</p>
          <a href="#" style={{ textDecoration: "none", color: "#FFFFFF" }}>
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

const LatestNews = () => {
  return (
    <section
      className="latest-news-section"
      style={{
        backgroundColor: "#000",
        paddingTop: "40px",
        paddingBottom: "40px",
      }}
    >
      <div className="container" style={{ borderBottom: "2px #ffffff solid" }}>
        <h5 className="section-title" style={{ color: "#FFFFFF" }}>
          Things to know about our Solar System
        </h5>
        <div className="row">
          <LatestNewsCard
            imageUrl={newsImage1}
            title="The Rocky Worlds"
            subtext="Learn about the inner planets of our solar
            system, their similarities, differences, and their
            importance in understanding terrestrial
            planet formation"
          />
          <LatestNewsCard
            imageUrl={newsImage2}
            title="Solar system origin"
            subtext="Explore how our solar system formed billions
            of years ago, trom the collapse of a giant
            molecular cloud to the formation of the Sun
            and planets."
          />
          <LatestNewsCard
            imageUrl={newsImage3}
            title="The Gas and ice Giants"
            subtext="Discover the giant planets of our solar system,
            their massive size, unique features, and their
            roles in shaping the outer solar system."
          />
          <LatestNewsCard
            imageUrl={newsImage4}
            title="Asteroids: Remnants of the
            Early Solar System"
            subtext="Explore the asteroid belt, a region between
            Mars and Jupiter filled with rocky remnants
            from the early solar system."
          />
        </div>
      </div>
    </section>
  );
};

const RemoteSense = () => {
  return (
    <>
      <Header />
      {/* <div
        style={{ backgroundColor: "#02020A", color: "#fff", padding: "20px" }}
      >
        <div className="container text-center">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo1} />
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo2} />
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo3} />
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo4} />
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo5} />
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <DataCopy1Icon2 alt="" src={logo6} />
            </div>
          </div>
        </div>
      </div> */}
      <GridComponent
        imageUrl={imgDetail3}
        text="URBAN PLANNING"
        subtext="Our satellite data analysis aids in urban planning, helping to design more efficient and sustainable cities."
      />
      <GetStarted />
      <GetStarted2 />
      <GridComponent
        imageUrl={imgDetail4}
        text="TRAFFIC MANAGEMENT"
        subtext="By analyzing satellite data, we provide insights for effective traffic management in urban areas, helping to alleviate congestion and improve transportation systems."
      />
      <GridComponent
        imageUrl={imgDetail2}
        text="SECURITY SOLUTIONS"
        subtext="We utilize drone technology to combat insurgency, enhancing security measures with high-tech surveillance and reconnaissance capabilities."
      />
      <Details />
      <LatestNews />
      <Footer />
    </>
  );
};

export default RemoteSense;
