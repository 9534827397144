import React from 'react';
import {
  Heading,
  Img,
  OurTeamCardBg,
  OurTeamCardContainer,
  LinkedinLogo,
} from './OurTeamCard.elements';
import { PlainText } from '../../globalStyles';

const OurTeamCard = ({ name, role, img, linkedinUrl }) => {
  return (
    <>
      <OurTeamCardContainer>
        <OurTeamCardBg>
          <Heading lightText={true}>{name}</Heading>
          <PlainText dark>{role}</PlainText>
        </OurTeamCardBg>
        <Img src={img} />
        <a href={linkedinUrl}>
          <LinkedinLogo src='/linkedIn.png' />
        </a>
      </OurTeamCardContainer>
    </>
  );
};

export default OurTeamCard;
