import React from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import Title from "./Title";
import Paragraph from "./Paragraph";
import backgroundImage from "../../../assets/img/back2.jpg"; // Import your background image
import { Card } from "antd";

const Details = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      phone: data.get("phone"),
    });
  };

  return (
    <Stack
      component="section"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${backgroundImage})`, // Set background image
        backgroundSize: "contain",
        backgroundPosition: "center",
        py: 10,
        px: 2,
      }}
    >
      <Card style={{ width: 400 }}>
        {" "}
        {/* Adjust the width as needed */}
        <Title text={"PARTNER WITH US"} textAlign={"center"} />
        <Paragraph
          text={
            "We are always interested in meeting like-minded businesses, or talented individuals. Please feel free to reach out to us."
          }
          maxWidth={"sm"}
          mx={0}
          textAlign={"center"}
        />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            py: 2,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
          />
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            margin="normal"
            fullWidth
            rows={4}
          />
          <Button
            variant="contained"
            fullWidth
            type="submit"
            size="medium"
            sx={{
              fontSize: "0.9rem",
              textTransform: "capitalize",
              py: 2,
              mt: 3,
              mb: 2,
              borderRadius: 0,
              backgroundColor: "#14192d",
              "&:hover": {
                backgroundColor: "#1e2a5a",
              },
            }}
          >
            send
          </Button>
        </Box>
      </Card>
    </Stack>
  );
};

export default Details;
