import React from "react";

import BenefitsSection from "../../components/Benefits/BenefitsSection";
import { homeObjOne, careerTwo, careerThree, careerHeader } from "./Data";
import VacancySection from "../../components/Vacancy/VacancySection";
import CoreStrengthSection from "../../components/CoreStrength/CoreStrengthSection";
import CareerHeaderSection from "../../components/CareerHeader/CareerHeaderSection";
import { InfoSectionCareer, Footer } from "../../components";

function Careers() {
  return (
    <>
      <InfoSectionCareer {...careerHeader} />
      <CoreStrengthSection {...careerThree} />
      <BenefitsSection {...homeObjOne} />
      <VacancySection {...careerTwo} />
      <Footer />
    </>
  );
}

export default Careers;
